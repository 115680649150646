<!-- src/components/PerformanceReport.vue -->
<template>
  <v-card v-if="report">
    <v-card-title>Performance Report</v-card-title>
    <v-card-text>
      <div><strong>Net Profit:</strong> {{ report.net_profit }}</div>
      <div><strong>Average Drawdown:</strong> {{ report.average_drawdown }}</div>
      <div><strong>Average Drawdown Day Loss:</strong> {{ report.average_drawdown_day_loss }}</div>
      <div><strong>Max Drawdown:</strong> {{ report.max_drawdown }}</div>
      <div><strong>Net Profit / Max Drawdown:</strong> {{ report.net_profit_max_drawdown }}</div>
      <div v-if="report.net_profit_std !== undefined"><strong>Net Profit Std:</strong> {{ report.net_profit_std }}</div>
      <div v-if="report.profit_factor !== undefined"><strong>Profit Factor:</strong> {{ report.profit_factor }}</div>
      <div v-if="report['%_profitable'] !== undefined"><strong>% Profitable:</strong> {{ report['%_profitable'] }}</div>
      <div v-if="report.avg_trade !== undefined"><strong>Avg Trade:</strong> {{ report.avg_trade }}</div>
      <div v-if="report.number_of_trades !== undefined"><strong>Number of Trades:</strong> {{ report.number_of_trades }}</div>
      <div v-if="report.average_slippage !== undefined"><strong>Average Slippage:</strong> {{ report.average_slippage }}</div>
      <div v-if="report.total_slippage !== undefined"><strong>Total Slippage:</strong> {{ report.total_slippage }}</div>
      <div v-if="report.max_required_margin !== undefined"><strong>Max Required Margin:</strong> {{ report.max_required_margin }}</div>
      <div v-if="report.current_drawdown_max_drawdown !== undefined"><strong>Current Drawdown / Max Drawdown:</strong> {{ report.current_drawdown_max_drawdown }}</div>
      <div v-if="report.distance_lower_bollinger_band !== undefined"><strong>Distance from Lower Bollinger Band (200 days):</strong> {{ report.distance_lower_bollinger_band }}</div>
      <div v-if="report.chart">
        <h3>Equity and Drawdown Chart</h3>
        <img :src="'data:image/png;base64,' + report.chart" alt="Equity and Drawdown" />
      </div>
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-card-title>No Report Available</v-card-title>
    <v-card-text>
      <div>Il report non è disponibile o i dati sono incompleti.</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PerformanceReport',
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Aggiungi gli stili se necessario */
img {
  max-width: 100%;
  height: auto;
}
</style>
