<!-- src/components/TradingParametersForm.vue -->
<template>
  <v-container>
    <!-- Intestazione Informativa -->
    <div class="container">
      <h2>Funzionamento Attuale dell'App</h2>
      <p class="intro">
        Attualmente l’app funziona con menù a tendina per scegliere ogni impostazione. 
        Eventualmente vedrò come renderla esteticamente più piacevole, magari mettendo gli elementi su più colonne.
      </p>
      
      <h3>Caricamento dei Dati Storici</h3>
      <p>
        Per ogni strumento finanziario bisogna caricare i dati storici (1 volta, poi rimane in memoria). 
        In futuro questa opzione sarà disponibile solo nel pannello admin.
      </p>
      
      <h3>Interfaccia Drag & Drop</h3>
      <p>
        Stavo iniziando a vedere anche l’implementazione di un’interfaccia drag&drop (demo selezionabile nella tab qua in alto), ma per quello ci vuole molto lavoro, quindi magari lo vedremo bene in futuro ad app “conclusa”.
      </p>
      
      <h3>Limitazioni Attuali</h3>
      <p>
        Al momento l’unica cosa del form che non è collegata effettivamente è la selezione del timeframe (quindi selezionando Timeframe diversi il risultato non cambia).
      </p>
      
      <h3>Richieste</h3>
      <ul>
        <li>Dati esatti che vorresti far vedere nel Performance Report e formule per calcolarli.</li>
        <li>Soglie sopra i quali ogni dato è “verde” o “rosso”.</li>
        <li>Vorresti che gli utenti si possano iscrivere autonomamente? <br>Cosa metteresti nel piano “free” se esiste?</li>
      </ul>
<br>
      <h5>P.S</h5>
      <p>
        Il backtest attuale ci mette più tempo del normale perché ho caricato dati storici con timeframe di 1m. Eventualmente dividerò il tutto in due pulsanti (BACKTEST | CODICE), visto che la generazione del codice easylanguage è istantanea.
      </p>
    </div>
    <br>
    <v-form v-model="valid" @submit.prevent="submitForm">
      <!-- Strumento Finanziario -->
      <v-select
        label="Strumento Finanziario"
        v-model="form.financial_instrument"
        :items="financialInstruments"
        required
      ></v-select>

      <!-- Condizioni di Ingresso -->
      <v-select
        label="Condizioni di Ingresso"
        v-model="form.entry_conditions"
        :items="entryConditions"
        required
      ></v-select>

      <!-- Filtri -->
      <v-select
        label="Filtri"
        v-model="form.filters"
        :items="filters"
        required
      ></v-select>

      <!-- Condizioni di Uscita -->
      <v-select
        label="Condizioni di Uscita"
        v-model="form.exit_conditions"
        :items="exitConditions"
        required
      ></v-select>

      <!-- Stop Loss -->
      <v-text-field
        label="Stop Loss"
        v-model.number="form.stop_loss"
        type="number"
        required
      ></v-text-field>

      <!-- Take Profit -->
      <v-text-field
        label="Take Profit"
        v-model.number="form.take_profit"
        type="number"
        required
      ></v-text-field>

      <!-- Valore Uscita -->
      <v-text-field
        label="Valore Uscita"
        v-model.number="form.valore_uscita"
        type="number"
        required
      ></v-text-field>

      <!-- Valore Filtro -->
      <v-text-field
        label="Valore Filtro"
        v-model.number="form.valore_filtro"
        type="number"
        required
      ></v-text-field>

      <!-- Soglia -->
      <v-text-field
        label="Soglia"
        v-model.number="form.soglia"
        type="number"
        required
      ></v-text-field>

      <!-- Divider -->
      <v-divider class="my-4">Backtest</v-divider>

      <!-- Selettori di Date -->
      <v-row>
        <!-- Data Inizio -->
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="form.start_date"
            label="Data Inizio"
            type="date"
            :max="form.end_date || todayFormatted"
            required
          ></v-text-field>
        </v-col>

        <!-- Data Fine -->
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="form.end_date"
            label="Data Fine"
            type="date"
            :min="form.start_date || null"
            :max="todayFormatted"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Timeframe -->
      <v-select
        label="Timeframe"
        v-model="form.timeframe"
        :items="timeframes"
        required
      ></v-select>

      <!-- Size -->
      <v-text-field
        label="Size"
        v-model.number="form.size"
        type="number"
        required
      ></v-text-field>

      <!-- Capitale Iniziale -->
      <v-text-field
        label="Capitale Iniziale"
        v-model.number="form.start_capital"
        type="number"
        required
      ></v-text-field>

      <!-- Caricamento dei Dati Storici -->
      <v-file-input
        label="Carica Dati Storici"
        v-model="historicalDataFile"
        accept=".csv"
        prepend-icon="mdi-upload"
        @change="uploadHistoricalData"
      ></v-file-input>

      <!-- Pulsante di Submit con Loading -->
      <v-btn
        type="submit"
        color="primary"
        class="mt-4"
        :loading="isBacktestRunning"
      >
        Genera Report
      </v-btn>

      <!-- Barra di Progresso -->
      <v-progress-linear
        v-if="isBacktestRunning"
        v-model="progressPercentage"
        class="mt-4"
        color="primary"
        height="10"
        striped
        animated
      >
        {{ progressPercentage }}%
      </v-progress-linear>

      <!-- Visualizzazione dei Risultati -->
      <div v-if="results" class="mt-4">
        <h3>Risultati del Backtest</h3>
        <p>Net Profit: {{ results.net_profit }}</p>
        <p>Average Drawdown: {{ results.average_drawdown }}</p>
        <p>Max Drawdown: {{ results.max_drawdown }}</p>
        <p>Net Profit / Max Drawdown: {{ results.net_profit_max_drawdown }}</p>
        <!-- Aggiungi altri risultati se necessario -->
      </div>

      <!-- Visualizzazione del Codice EasyLanguage (Solo per Utenti Loggati) -->
      <div v-if="easyLanguageScript" class="mt-4">
        <h3>Codice EasyLanguage</h3>
        <pre
          class="copyable-code language-easylanguage"
          @click="copyEasyLanguageCode"
        >
          <code ref="easyCodeBlock" class="language-easylanguage">
            {{ easyLanguageScript }}
          </code>
        </pre>
        <v-btn @click="downloadEasyLanguageCode" color="primary" class="mt-2">
          Scarica Codice
        </v-btn>
      </div>

      <!-- Grafico del Backtest -->
      <div v-if="plotData && plotLayout" class="mt-4">
        <PlotlyChart :data="plotData" :layout="plotLayout"></PlotlyChart>
      </div>

      <!-- Snackbar per le notifiche -->
      <v-snackbar v-model="snackbar" :timeout="3000" top right>
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Chiudi
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';
import PlotlyChart from './PlotlyChart.vue';
import { defineComponent } from 'vue';
import hljs from 'highlight.js/lib/core';
// Importa un linguaggio per la sintassi (vbnet come esempio più vicino a EasyLanguage)
import vbnet from 'highlight.js/lib/languages/vbnet';
import 'highlight.js/styles/default.css'; // Scegli il tema che preferisci

// Registra il linguaggio per EasyLanguage
hljs.registerLanguage('easylanguage', vbnet);

const API_URL = process.env.VUE_APP_API_URL || 'http://127.0.0.1:5000';

export default defineComponent({
  name: 'TradingParametersForm',
  components: {
    PlotlyChart,
  },
  data() {
    return {
      valid: false,
      isBacktestRunning: false,
      progressPercentage: 0,
      backtestResult: null,
      results: null,
      easyLanguageScript: null,
      today: new Date(), // Data odierna
      form: {
        financial_instrument: 'ES',
        entry_conditions: 'Long',
        filters: 'Filtro 1',
        exit_conditions: 'Condizione 1',
        stop_loss: 100,
        take_profit: 200,
        valore_uscita: 5,
        valore_filtro: 10,
        soglia: 50,
        timeframe: 'Daily',
        start_date: '2010-10-10',
        end_date: '2011-10-10',
        size: 1,
        start_capital: 10000,
      },
      financialInstruments: [
        'ES',
        'NQ',
        'GC',
        'CL',
        'EC',
        'S',
        'W',
        'FDAX',
        'FESX',
        'HG',
        'NG',
        'TY',
        'BTC',
      ],

      entryConditions: ['Long', 'Short'],
      filters: [
        'Filtro 1',
        'Filtro 1b',
        'Filtro 2',
        'Filtro 2b',
        'Filtro 3',
        'Filtro 3b',
        'Filtro 4',
        'Filtro 4b',
        'Filtro 5',
        'Filtro 5b',
      ],
      exitConditions: ['Condizione 1', 'Condizione 2'],
      timeframes: ['Daily', '1440 min', '690 min', '460 min', '345 min', '230 min', '60 min'],
      historicalDataFile: null,
      snackbar: false,
      snackbarMessage: '',
    };
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem('token');
    },
    plotData() {
      if (
        !this.backtestResult ||
        !this.backtestResult.dates ||
        !this.backtestResult.equity ||
        !this.backtestResult.drawdown
      ) {
        return null;
      }
      return [
        {
          x: this.backtestResult.dates,
          y: this.backtestResult.equity,
          type: 'scatter',
          mode: 'lines',
          name: 'Equity',
          line: { color: 'green' },
        },
        {
          x: this.backtestResult.dates,
          y: this.backtestResult.drawdown,
          type: 'scatter',
          mode: 'lines',
          name: 'Drawdown',
          line: { color: 'red' },
          yaxis: 'y2',
        },
      ];
    },
    plotLayout() {
      return {
        title: 'Grafico',
        xaxis: { title: 'Data' },
        yaxis: { title: 'Equity' },
        yaxis2: {
          title: 'Drawdown',
          overlaying: 'y',
          side: 'right',
          showgrid: false,
          zeroline: false,
        },
      };
    },
    todayFormatted() {
      // Formatta la data odierna in 'YYYY-MM-DD' per l'attributo max dei campi data
      const year = this.today.getFullYear();
      const month = String(this.today.getMonth() + 1).padStart(2, '0');
      const day = String(this.today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
  methods: {
    /**
     * Esegue il backtest quando il form viene sottomesso.
     */
    async submitForm() {
      // Validazione del form
      if (!this.valid) {
        this.snackbarMessage = 'Per favore, completa tutti i campi richiesti.';
        this.snackbar = true;
        return;
      }

      // Verifica che le date siano selezionate
      if (!this.form.start_date || !this.form.end_date) {
        this.snackbarMessage = 'Per favore, seleziona le date di inizio e fine.';
        this.snackbar = true;
        return;
      }

      const formData = {
        ...this.form,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
      };

      this.isBacktestRunning = true;
      this.progressPercentage = 0;

      try {
        // Simula l'aggiornamento della percentuale di avanzamento
        const progressInterval = setInterval(() => {
          if (this.progressPercentage < 90) {
            this.progressPercentage += 10;
            console.log('Progress:', this.progressPercentage); // Debugging
          }
        }, 500);

        // Esegui la chiamata al backend per eseguire il backtest
        const response = await this.runBacktest(formData);

        // Ferma l'aggiornamento della percentuale
        clearInterval(progressInterval);
        this.progressPercentage = 100;

        // Imposta i risultati del backtest
        this.backtestResult = response.data.chart;
        this.results = response.data.results;
        this.easyLanguageScript = response.data.easy_language_script;

        // Debugging: verifica se easyLanguageScript è stato ricevuto
        console.log('EasyLanguage Script:', this.easyLanguageScript);

        if (!this.easyLanguageScript) {
          this.snackbarMessage = 'Il codice EasyLanguage non è stato generato.';
          this.snackbar = true;
        }

        // Applica il syntax highlighting dopo aver ricevuto il codice
        this.$nextTick(() => {
          this.applyHighlighting();
        });
      } catch (error) {
        console.error("Errore durante l'esecuzione del backtest:", error);
        this.snackbarMessage = 'Errore durante l\'esecuzione del backtest.';
        this.snackbar = true;
      } finally {
        // Nascondi la barra di avanzamento dopo un breve ritardo
        setTimeout(() => {
          this.isBacktestRunning = false;
          this.progressPercentage = 0;
        }, 500);
      }
    },

    /**
     * Chiama l'API backend per eseguire il backtest.
     */
    async runBacktest(formData) {
      try {
        const headers = this.isLoggedIn
          ? { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          : {};

        const response = await axios.post(`${API_URL}/api/backtest`, formData, {
          headers,
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              this.progressPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              console.log('Download Progress:', this.progressPercentage); // Debugging
            }
          },
        });
        return response;
      } catch (error) {
        console.error('Errore durante la chiamata al backend:', error);
        this.snackbarMessage = error.response?.data?.message || 'Errore durante la chiamata al backend.';
        this.snackbar = true;
        throw error;
      }
    },

    /**
     * Copia il codice EasyLanguage negli appunti con fallback.
     */
    copyEasyLanguageCode() {
      if (!this.easyLanguageScript) {
        this.snackbarMessage = 'Nessun codice EasyLanguage da copiare.';
        this.snackbar = true;
        return;
      }

      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(this.easyLanguageScript)
          .then(() => {
            this.snackbarMessage = 'Codice copiato negli appunti!';
            this.snackbar = true;
          })
          .catch(err => {
            console.error('Errore nel copiare il codice:', err);
            this.snackbarMessage = 'Errore nel copiare il codice.';
            this.snackbar = true;
          });
      } else {
        // Fallback: utilizza una textarea temporanea
        const textarea = document.createElement('textarea');
        textarea.value = this.easyLanguageScript;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';
        document.body.appendChild(textarea);
        textarea.select();
        try {
          const successful = document.execCommand('copy');
          if (successful) {
            this.snackbarMessage = 'Codice copiato negli appunti!';
          } else {
            throw new Error('Copy command was unsuccessful');
          }
        } catch (err) {
          console.error('Errore nel copiare il codice:', err);
          this.snackbarMessage = 'Errore nel copiare il codice.';
        }
        document.body.removeChild(textarea);
        this.snackbar = true;
      }
    },

    /**
     * Scarica il codice EasyLanguage come file.
     */
    downloadEasyLanguageCode() {
      if (!this.easyLanguageScript) {
        this.snackbarMessage = 'Nessun codice EasyLanguage da scaricare.';
        this.snackbar = true;
        return;
      }

      const blob = new Blob([this.easyLanguageScript], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'codice_easylanguage.txt';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },

    /**
     * Carica i dati storici selezionati.
     */
    async uploadHistoricalData() {
      if (!this.historicalDataFile) {
        this.snackbarMessage = 'Nessun file selezionato per il caricamento.';
        this.snackbar = true;
        return;
      }

      const formData = new FormData();
      formData.append('file', this.historicalDataFile);
      formData.append('financial_instrument', this.form.financial_instrument);

      try {
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        if (this.isLoggedIn) {
          headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        }

        await axios.post(`${API_URL}/api/upload_data`, formData, {
          headers,
        });

        this.snackbarMessage = 'Dati storici caricati con successo!';
        this.snackbar = true;
      } catch (error) {
        console.error('Errore durante il caricamento dei dati storici:', error);
        this.snackbarMessage = error.response?.data?.message || 'Errore durante il caricamento dei dati storici.';
        this.snackbar = true;
      }
    },

    /**
     * Applica il syntax highlighting al codice EasyLanguage
     */
    applyHighlighting() {
      if (this.$refs.easyCodeBlock) {
        hljs.highlightElement(this.$refs.easyCodeBlock);
      }
    },
  },
  mounted() {
    // Applica il syntax highlighting al montaggio del componente
    this.applyHighlighting();
  },
  updated() {
    // Applica il syntax highlighting ogni volta che il componente viene aggiornato
    this.applyHighlighting();
  },
});
</script>

<style scoped>
/* Stili per l'introduzione */
.container {
  max-width: 800px;
  margin: auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

h3 {
  font-size: 20px;
  color: #34495e;
  margin-top: 20px;
}

p {
  margin: 10px 0;
}

.intro {
  font-style: italic;
  color: #7f8c8d;
}

ul {
  margin-top: 10px;
  padding-left: 20px;
}

ul li {
  margin-bottom: 5px;
}

@media (min-width: 600px) {
  .container {
    padding: 40px;
  }
}

/* Stili esistenti per il codice copiabile */
.copyable-code {
  cursor: pointer;
  background-color: #f5f5f5;
  padding: 1em;
  overflow: auto;
  position: relative;
}

.copyable-code::after {
  content: 'Clicca per copiare';
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.2em 0.5em;
  font-size: 0.8em;
  border-bottom-left-radius: 5px;
}

pre {
  position: relative;
}

pre code {
  display: block;
}
</style>
