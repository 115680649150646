<template>
  <v-container>
    <h1>Dashboard</h1>

    <!-- Interface Selector -->
    <v-btn-toggle v-model="selectedInterface" mandatory class="mb-4">
      <v-btn value="form">Form</v-btn>
      <v-btn value="blocks">Drag-and-Drop</v-btn>
    </v-btn-toggle>

    <!-- Form Interface -->
    <div v-if="selectedInterface === 'form'">
      <TradingParametersForm @submitForm="handleSubmit"></TradingParametersForm>
    </div>

    <!-- Blockly Interface -->
    <div v-else-if="selectedInterface === 'blocks'">
      <StrategyBuilder @submitCode="handleSubmitCode"></StrategyBuilder>
    </div>

    <!-- Backtest Results -->
    <PerformanceReport v-if="backtestResults" :report="backtestResults" />

    <!-- Error Message -->
    <v-alert v-if="errorMessage" type="error" dismissible class="mt-4">
      {{ errorMessage }}
    </v-alert>
  </v-container>
</template>

<script>
import TradingParametersForm from './TradingParametersForm.vue';
import StrategyBuilder from './StrategyBuilder.vue';
import PerformanceReport from './PerformanceReport.vue';
import axios from 'axios';

export default {
  name: 'UserDashboard',
  components: {
    TradingParametersForm,
    StrategyBuilder,
    PerformanceReport,
  },
  data() {
    return {
      selectedInterface: 'form',
      backtestResults: null,
      errorMessage: '',
    };
  },
  methods: {
    /**
     * Handles form data submission.
     */
    async handleSubmit(data) {
      try {
        const response = await axios.post('http://127.0.0.1:5000/api/backtest', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.data.results && !response.data.results.error) {
          this.backtestResults = response.data.results;
          if (response.data.chart) {
            this.backtestResults.chart = response.data.chart;
          }
          this.errorMessage = '';
        } else {
          this.errorMessage = response.data.error || 'Unknown error during backtest.';
          this.backtestResults = null;
        }
      } catch (error) {
        console.error('Error during submission:', error);
        this.errorMessage = 'An error occurred during submission. Please try again later.';
        this.backtestResults = null;
      }
    },
    /**
     * Handles code submission from Blockly.
     */
    handleSubmitCode() {
      // If the backend supports code submission, implement this method.
      // Since the current backend does not support it, we can display a message.
      this.errorMessage = 'Code submission via Blockly is not supported yet.';
    },
  },
};
</script>

<style scoped>
</style>
