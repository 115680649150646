<!-- src/components/StrategyBuilder.vue -->
<template>
    <v-container>
      <h2>Costruttore di Strategie</h2>
      
      <!-- Area di lavoro di Blockly -->
      <div id="blocklyDiv" style="height: 480px; width: 100%;"></div>
      
      <!-- Pulsanti per generare e inviare il codice -->
      <v-btn @click="generateCode" color="primary" class="mt-2">Genera Codice</v-btn>
      
      <v-card v-if="generatedCode" class="mt-4">
        <v-card-title>Codice Generato</v-card-title>
        <v-card-text>
          <pre>{{ generatedCode }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submitCode" color="success">Invia Strategia</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
</template>

<script>
// Importa Blockly e le sue dipendenze
import * as Blockly from 'blockly';
import 'blockly/blocks'; // Importa i blocchi standard di Blockly
import * as BlocklyLocale from 'blockly/msg/it'; // Importa tutte le stringhe di localizzazione
import '../blocks/tradingBlocks'; // Importa i blocchi personalizzati
import tradingLanguageGenerator from '../generators/trading_language_generator'; // Importa il generatore personalizzato

// Imposta la localizzazione di Blockly
Blockly.setLocale(BlocklyLocale);

export default {
  name: 'StrategyBuilder',
  data() {
    return {
      workspace: null,
      generatedCode: '',
    };
  },
  mounted() {
    // Inizializza l'area di lavoro di Blockly
    this.workspace = Blockly.inject('blocklyDiv', {
      toolbox: this.getToolbox(),
      grid: {
        spacing: 20,
        length: 3,
        colour: '#ccc',
        snap: true,
      },
      trashcan: true,
    });
  },
  methods: {
    /**
     * Definisce la cassetta degli attrezzi di Blockly con i blocchi disponibili.
     * @returns {string} - XML della toolbox di Blockly.
     */
    getToolbox() {
      return `
        <xml>
          <category name="Logica" colour="%{BKY_LOGIC_HUE}">
            <block type="controls_if"></block>
            <block type="logic_compare"></block>
            <block type="logic_operation"></block>
            <block type="logic_negate"></block>
          </category>
          <category name="Matematica" colour="%{BKY_MATH_HUE}">
            <block type="math_number"></block>
            <block type="math_arithmetic"></block>
          </category>
          <category name="Indicatori Tecnici" colour="%{BKY_INDICATORS_HUE}">
            <block type="moving_average"></block>
            <block type="rsi_indicator"></block>
          </category>
          <category name="Azioni di Trading" colour="%{BKY_VARIABLES_HUE}">
            <block type="buy_action"></block>
            <block type="sell_action"></block>
          </category>
        </xml>
      `;
    },
    /**
     * Genera il codice nel linguaggio di trading utilizzando il generatore personalizzato.
     */
    generateCode() {
      const code = tradingLanguageGenerator.workspaceToCode(this.workspace);
      this.generatedCode = code;
    },
    /**
     * Emette l'evento con il codice generato per la sottomissione.
     */
    submitCode() {
      // Emette l'evento con il codice generato
      this.$emit('submitCode', { trading_code: this.generatedCode });
    },
  },
};
</script>

<style scoped>
#blocklyDiv {
  border: 1px solid #ccc;
}
</style>
