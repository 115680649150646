<template>
  <v-container>
    <v-form @submit.prevent="login">
      <v-text-field v-model="username" label="Username" required></v-text-field>
      <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
      <v-btn type="submit" color="primary">Login</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async login() {
      try {
        const formData = new FormData();
        formData.append('password', this.password);

        const response = await axios.post(
          `http://127.0.0.1:5000/api/users/${this.username}/tokens/`,
          formData,
        );

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', this.username);
        localStorage.setItem('is_admin', response.data.is_admin);

        this.$router.push('/');
      } catch (error) {
        console.error('Errore durante il login:', error);
        alert('Credenziali non valide');
      }
    },
  },
};
</script>
