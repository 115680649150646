// backend/trading_language_generator.js
import * as Blockly from 'blockly';

/**
 * Crea un nuovo oggetto generatore per il linguaggio di trading.
 */
const tradingLanguageGenerator = new Blockly.Generator('TradingLanguage');

// Imposta la precedenza degli operatori (opzionale)
tradingLanguageGenerator.PRECEDENCE = 0;

/**
 * Genera codice per il blocco dei numeri.
 */
tradingLanguageGenerator['math_number'] = function(block) {
  const code = block.getFieldValue('NUM');
  return [code, tradingLanguageGenerator.ORDER_ATOMIC];
};

/**
 * Genera codice per le operazioni matematiche.
 */
tradingLanguageGenerator['math_arithmetic'] = function(block) {
  const OPERATORS = {
    'ADD': '+',
    'MINUS': '-',
    'MULTIPLY': '*',
    'DIVIDE': '/',
    'POWER': '^',
  };
  const operator = OPERATORS[block.getFieldValue('OP')];
  const order = tradingLanguageGenerator.ORDER_ATOMIC;
  const argument0 = tradingLanguageGenerator.valueToCode(block, 'A', order) || '0';
  const argument1 = tradingLanguageGenerator.valueToCode(block, 'B', order) || '0';
  const code = `${argument0} ${operator} ${argument1}`;
  return [code, order];
};

/**
 * Genera codice per le condizioni "if".
 */
tradingLanguageGenerator['controls_if'] = function(block) {
  let n = 0;
  let code = '';
  let branchCode, conditionCode;
  do {
    conditionCode = tradingLanguageGenerator.valueToCode(block, 'IF' + n, tradingLanguageGenerator.ORDER_NONE) || 'false';
    branchCode = tradingLanguageGenerator.statementToCode(block, 'DO' + n) || '';
    code += `IF ${conditionCode} THEN\n${branchCode}\n`;
    n++;
  } while (block.getInput('IF' + n));

  return code;
};

/**
 * Genera codice per le comparazioni logiche.
 */
tradingLanguageGenerator['logic_compare'] = function(block) {
  const OPERATORS = {
    'EQ': '=',
    'NEQ': '<>',
    'LT': '<',
    'LTE': '<=',
    'GT': '>',
    'GTE': '>=',
  };
  const operator = OPERATORS[block.getFieldValue('OP')];
  const order = tradingLanguageGenerator.ORDER_RELATIONAL;
  const argument0 = tradingLanguageGenerator.valueToCode(block, 'A', order) || '0';
  const argument1 = tradingLanguageGenerator.valueToCode(block, 'B', order) || '0';
  const code = `${argument0} ${operator} ${argument1}`;
  return [code, order];
};

/**
 * Genera codice per le operazioni logiche.
 */
tradingLanguageGenerator['logic_operation'] = function(block) {
  const operator = (block.getFieldValue('OP') === 'AND') ? 'AND' : 'OR';
  const order = tradingLanguageGenerator.ORDER_LOGICAL_AND;
  const argument0 = tradingLanguageGenerator.valueToCode(block, 'A', order) || 'false';
  const argument1 = tradingLanguageGenerator.valueToCode(block, 'B', order) || 'false';
  const code = `${argument0} ${operator} ${argument1}`;
  return [code, order];
};

/**
 * Genera codice per la negazione logica.
 */
tradingLanguageGenerator['logic_negate'] = function(block) {
  const order = tradingLanguageGenerator.ORDER_LOGICAL_NOT;
  const argument0 = tradingLanguageGenerator.valueToCode(block, 'BOOL', order) || 'false';
  const code = `NOT (${argument0})`;
  return [code, order];
};

/**
 * Genera codice per il blocco della Media Mobile.
 */
tradingLanguageGenerator['moving_average'] = function(block) {
  const period = tradingLanguageGenerator.valueToCode(block, 'INPUT', tradingLanguageGenerator.ORDER_ATOMIC) || '14';
  const code = `Average(Close, ${period})`;
  return [code, tradingLanguageGenerator.ORDER_ATOMIC];
};

/**
 * Genera codice per il blocco dell'RSI.
 */
tradingLanguageGenerator['rsi_indicator'] = function(block) {
  const period = tradingLanguageGenerator.valueToCode(block, 'INPUT', tradingLanguageGenerator.ORDER_ATOMIC) || '14';
  const code = `RSI(Close, ${period})`;
  return [code, tradingLanguageGenerator.ORDER_ATOMIC];
};

/**
 * Genera codice per l'azione di acquisto.
 */
tradingLanguageGenerator['buy_action'] = function() {
  const code = 'Buy Next Bar at Market;\n';
  return code;
};

/**
 * Genera codice per l'azione di vendita.
 */
tradingLanguageGenerator['sell_action'] = function() {
  const code = 'Sell Next Bar at Market;\n';
  return code;
};

// Esporta il generatore personalizzato
export default tradingLanguageGenerator;
