// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import UserDashboard from '../components/UserDashboard.vue';
import UserLogin from '../components/UserLogin.vue';
import AdminDashboard from '../components/AdminDashboard.vue';
import NotFound from '../components/NotFound.vue'; // Assicurati di creare questo componente

const routes = [
  {
    path: '/',
    name: 'Home',
    component: UserDashboard,
    meta: { requiresAuth: false }, // Accessibile a tutti
  },
  {
    path: '/login',
    name: 'Login',
    component: UserLogin,
    meta: { requiresAuth: false },
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true }, // Solo admin
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound, // Pagina 404
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guards
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const isAdmin = localStorage.getItem('is_admin') === 'true';

  if (to.meta.requiresAuth) {
    if (!token) {
      // L'utente non è loggato, reindirizza al login
      return next('/login');
    }

    if (to.meta.requiresAdmin && !isAdmin) {
      // L'utente non ha i privilegi admin, reindirizza alla home o a una pagina di errore
      return next('/');
    }
  }

  next();
});

export default router;
