// backend/tradingBlocks.js
import * as Blockly from 'blockly';

/**
 * Blocco per la Media Mobile.
 */
Blockly.Blocks['moving_average'] = {
  init: function() {
    this.appendValueInput("INPUT")
        .setCheck("Number")
        .appendField("Media Mobile Periodi");
    this.setOutput(true, "Number");
    this.setColour(230);
    this.setTooltip("Calcola la media mobile");
    this.setHelpUrl("");
  },
};

/**
 * Blocco per l'RSI.
 */
Blockly.Blocks['rsi_indicator'] = {
  init: function() {
    this.appendValueInput("INPUT")
        .setCheck("Number")
        .appendField("RSI Periodi");
    this.setOutput(true, "Number");
    this.setColour(230);
    this.setTooltip("Calcola l'RSI");
    this.setHelpUrl("");
  },
};

/**
 * Blocco per l'azione di acquisto.
 */
Blockly.Blocks['buy_action'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Compra");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip("Esegue un ordine di acquisto");
    this.setHelpUrl("");
  },
};

/**
 * Blocco per l'azione di vendita.
 */
Blockly.Blocks['sell_action'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Vendi");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
    this.setTooltip("Esegue un ordine di vendita");
    this.setHelpUrl("");
  },
};

// Aggiungi altri blocchi personalizzati se necessario
