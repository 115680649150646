<!-- src/components/PlotlyChart.vue -->
<template>
    <div ref="plotlyChart"></div>
  </template>
  
  <script>
  import Plotly from 'plotly.js-dist';
  
  export default {
    name: 'PlotlyChart',
    props: ['data', 'layout'],
    mounted() {
      if (this.data && this.layout) {
        Plotly.newPlot(this.$refs.plotlyChart, this.data, this.layout);
      }
    },
    watch: {
      data(newData) {
        if (newData && this.layout) {
          Plotly.react(this.$refs.plotlyChart, newData, this.layout);
        }
      },
      layout(newLayout) {
        if (newLayout && this.data) {
          Plotly.react(this.$refs.plotlyChart, this.data, newLayout);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Aggiungi stili personalizzati se necessario */
  </style>
  