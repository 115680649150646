<template>
  <v-container>
    <v-card>
      <v-card-title>Gestione Utenti</v-card-title>
      <v-card-text>
        <!-- Modulo di Creazione Utente -->
        <v-form v-model="valid" @submit.prevent="createUser">
          <v-text-field
            label="Username"
            v-model="newUser.username"
            :rules="[v => !!v || 'Username obbligatorio']"
            required
          ></v-text-field>
          <v-text-field
            label="Email"
            v-model="newUser.email"
            :rules="[v => !!v || 'Email obbligatoria', v => /.+@.+\..+/.test(v) || 'Email non valida']"
            required
          ></v-text-field>
          <v-text-field
            label="Password"
            v-model="newUser.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :rules="[v => !!v || 'Password obbligatoria']"
            required
          ></v-text-field>
          <v-btn type="submit" color="primary" :disabled="!valid">Crea Utente</v-btn>
        </v-form>

        <!-- Tabella Utenti -->
        <v-data-table
          :headers="headers"
          :items="users"
          item-key="username"
          class="mt-5"
        >
          <!-- Personalizzazione della cella 'Azioni' senza utilizzare slot con modificatori -->
          <template #item="{ item }">
            <tr>
              <td>{{ item.username }}</td>
              <td>
                <v-btn @click="deleteUser(item.username)" color="red" text>Elimina</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      valid: false,
      showPassword: false,
      newUser: {
        username: '',
        email: '',
        password: '',
      },
      users: [],
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Azioni', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('http://127.0.0.1:5000/api/users/', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.users = response.data;
      } catch (error) {
        console.error('Errore nel recupero degli utenti:', error);
        alert('Errore nel recupero degli utenti');
      }
    },
    async createUser() {
      if (!this.valid) {
        return;
      }

      try {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('email', this.newUser.email);
        formData.append('password', this.newUser.password);

        await axios.put(
          `http://127.0.0.1:5000/api/users/${this.newUser.username}`,
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        this.fetchUsers();
        alert('Utente creato con successo');
        // Resetta il modulo
        this.newUser.username = '';
        this.newUser.email = '';
        this.newUser.password = '';
        this.valid = false;
      } catch (error) {
        console.error("Errore nella creazione dell'utente:", error);
        alert('Errore nella creazione dell\'utente');
      }
    },
    async deleteUser(username) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`http://127.0.0.1:5000/api/users/${username}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.fetchUsers();
        alert('Utente eliminato con successo');
      } catch (error) {
        console.error("Errore nell'eliminazione dell'utente:", error);
        alert('Errore nell\'eliminazione dell\'utente');
      }
    },
  }, // <-- Assicurati che la virgola sia qui
};
</script>

<style scoped>
</style>
