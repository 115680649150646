<!-- src/components/NavigationBar.vue -->
<template>
  <v-app-bar app color="primary" dark>
    <v-toolbar-title @click="$router.push('/')">Trading App</v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- Mostra il pulsante Login solo se l'utente non è loggato -->
    <v-btn v-if="!isLoggedIn" @click="$router.push('/login')" text>Login</v-btn>

    <!-- Mostra il pulsante Dashboard a tutti gli utenti -->
    <v-btn @click="$router.push('/')" text>Dashboard</v-btn>

    <!-- Mostra il pulsante Dashboard Admin solo se l'utente è admin -->
    <v-btn v-if="isLoggedIn && isAdmin" @click="$router.push('/admin')" text>Dashboard Admin</v-btn>

    <!-- Mostra il pulsante Logout solo se l'utente è loggato -->
    <v-btn v-if="isLoggedIn" @click="logout" text>Logout</v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'NavigationBar',
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem('token');
    },
    isAdmin() {
      return localStorage.getItem('is_admin') === 'true';
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('is_admin');
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
/* Aggiungi eventuali stili personalizzati qui */
</style>
