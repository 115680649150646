<!-- src/components/NotFound.vue -->
<template>
  <v-container class="text-center mt-10">
    <h1>404 - Pagina Non Trovata</h1>
    <p>La pagina che stai cercando non esiste.</p>
    <v-btn color="primary" @click="$router.push('/')">Torna alla Home</v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.mt-10 {
  margin-top: 10vh;
}
</style>
